<template>
  <div class="checkWork"  v-loading.fullscreen.lock="fullscreenLoading">
    <div class="checkWork-con">
        <div class="imageList" @dragover="dragover($event)">
            <transition-group class="transition-wrapper" name="sort">
                <div v-for="item in dataList" :key="item.id" class="imageItem" 
                        :draggable="true" 
                        @dragstart="dragstart(item)"
                        @dragenter="dragenter(item,$event)"
                        @dragend="dragend(item,$event)"
                        @dragover="dragover($event)"> 
                    <div class="image"  element-loading-background="rgba(0, 0, 0, 0.8)">
                        <img :src='item.imgUrl' alt="上传照片" class="img" @click="imgView(item)">
                        <!-- <div class="mask">
                            <div class="opIcon">
                                <i class="el-icon-view maskIconOne" @click="imgView(item)"></i>
                                <i class="el-icon-delete maskIconTwo" @click="sureDelete(item)"></i>
                            </div>
                        </div> -->
                        <div class="delete">
                            <i class="el-icon-delete deleteIcon" @click="sureDelete(item)"></i>
                        </div>
                    </div>
                </div>
                <!-- 此处的key="2020"为动画的标识 -->
                <div class="addImg" key="2020">
                    <el-upload
                        :action="requestAction"
                        :headers="requestHeaders"
                        list-type="picture-card"
                        :show-file-list="false"
                        :on-success="handleSuccess"
                        :before-upload="beforeUpload"
                        >
                        <div>
                            <i class="el-icon-circle-plus-outline icon" ></i>
                            <div class="words">
                                <span class="title">
                                    上传图片
                                </span>
                            </div>
                        </div>
                    </el-upload>
                </div>
            </transition-group>
        </div>
    </div>
    <el-dialog title="提示" :visible.sync="deleteDialogVisible" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" width="0%">
        <div class="checkSucCard">
            <div class="checkSuc">
                <div class="tip"></div>
                <div class="delete">
                    <div class="deleteImg">
                         <div class="deleteImgTip">{{deleteImgTip}}</div>
                    </div>
                </div>
                <div class="operation">
                    <div class="cancel" @click="cancel">取消</div>
                    <div class="deleteImg" @click="deleteImg">确认</div>
                </div>
            </div>
        </div>
    </el-dialog>
    <el-dialog title="图片" :visible.sync="imgViewdialogVisible" width="30%" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <viewer :images="[viewingImg]">
            <img v-for="(item, index) in [viewingImg]" :src="viewingImg" :key="index" style="width: 100%"/>
        </viewer>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="imgViewdialogVisible = false">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import { page } from './../../api/index.js';
import { Message } from 'element-ui';
import httpConfig from '@/http/config.js';
export default {
  name: "classroomFocus",
  
  data() {
    return {
        paikeId: '',
        boyAva: require('../../assets/image/guide.png'),
        dataList: [],
        fullscreenLoading: false,
        oldData: null,
        newData: null,
        deleteDialogVisible: false,
        deleteImgTip: '确定删除该图片？',
        // 上传请求地址
        requestAction: '',
        // 上传请求头
        requestHeaders: {},
        upLoadImg: '',
        id: '',
        imgViewdialogVisible: false,
        viewingImg: ''
    };
  },
  mounted(){
    this.requestAction = httpConfig.httpConfig.baseURL + '/sys/file/upload/picture';
    this.requestHeaders = { Authorization: this.$store.getters.token };
    this.getCoursePaikeId();
    // this.getPaikeIdForQuery().then((res) => {
    //     this.getBasicInfo(res)
    // }).catch(() => {
    //     this.getPaikeId().then((res) => {
    //         this.getBasicInfo(res)
    //     }).catch(() => {
    //         this.getPaikeIdForCurrDayLatestClass().then((res) => {
    //             this.getBasicInfo(res)
    //         }).catch(() => {});
    //     });
    // })
  },
  methods: {
        // getBasicInfo(res){
        //     this.paikeId = res.data.paikeId;
        //     this.getAflpClassFocusRecord();
        // },
        async getCoursePaikeId(){
            this.fullscreenLoading = true;
            await page.getCoursePaikeId('', res => {
                console.log(res);
                if(res.status === 1) {
                    if(res.data && res.data.courseName){
                        this.paikeId = res.data.scheduleId;
                        this.getAflpClassFocusRecord();
                    }else{
                        Message.error('未查询到排课Id');
                    }
                }else {
                    Message.error(res.message);
                }
                this.fullscreenLoading = false;
            });
        },
        // getPaikeId(){
        //     this.fullscreenLoading = true;
        //     const that = this;
        //     return new Promise(function(resolve,reject){
        //         page.getCurrDayOnClass({}, res => {
        //             if(res.status === 1) {
        //                 if(res.data && res.data.courseName){
        //                     that.fullscreenLoading = false;
        //                     resolve(res)
        //                 }else{
        //                     that.fullscreenLoading = false;
        //                     reject()
        //                 }   
        //             }else {
        //                 that.fullscreenLoading = false;
        //                 Message.error(res.message);
        //             }
        //         });
        //     })
        // },
        // getPaikeIdForQuery(){
        //     const that = this;
        //     return new Promise(function(resolve,reject){
        //         if(!that.$route.query.paikeId){
        //             reject()
        //         }else{
        //             page.getCourseInfoByPaikeId(that.$route.query.paikeId, res => {
        //                 if(res.status === 1) {
        //                     if(res.data && res.data.courseName){
        //                         resolve(res)
        //                     }else{
        //                         reject()
        //                     }
        //                 }else {
        //                     Message.error(res.message);
        //                 }
        //             });
        //         }
        //     })
        // },
        // getPaikeIdForCurrDayLatestClass(){
        //     const that = this;
        //     return new Promise(function(resolve,reject){
        //         page.getCurrDayLatestClassTime({}, res => {
        //             if(res.status === 1) {
        //                 if(res.data && res.data.courseName){
        //                     resolve(res)
        //                 }else{
        //                     reject()
        //                 }
        //             }else {
        //                 Message.error(res.message);
        //             }
        //             that.fullscreenLoading = false;
        //         });
        //     })
        // },
        getAflpClassFocusRecord(){
            this.fullscreenLoading = true;
            page.aflpClassFocusRecord(this.paikeId,res => {
                if(res.status === 1) {
                    this.dataList = res.data;
                    this.fullscreenLoading = false;
                }else {
                    this.fullscreenLoading = false;
                    Message.error(res.message);
                }
            });
        },
        dragstart(value) {
            this.oldData = value
        },
        // 记录移动过程中信息
        dragenter(value, e) {
            this.newData = value
            e.preventDefault()
        },
        // 拖拽最终操作
        dragend(value, e) {
            console.log(value, e)
            console.log(this.oldData,this.newData)
            if (this.oldData !== this.newData) {
                let oldIndex = this.dataList.indexOf(this.oldData)
                let newIndex = this.dataList.indexOf(this.newData)
                let newItems = [...this.dataList]
                // 删除老的节点
                newItems.splice(oldIndex, 1)
                // 在列表中目标位置增加新的节点
                newItems.splice(newIndex, 0, this.oldData)
                this.dataList = [...newItems]
                const params = {
                    source: this.oldData.id,
                    target: this.newData.id
                }
                page.aflpUnLockSort(params, res => {
                    if(res.status === 1) {
                        Message.success('调换成功！');
                        this.getAflpClassFocusRecord();
                    }else {
                        Message.error(res.message);
                    }
                });
            }
        },
        // 拖动事件（主要是为了拖动时鼠标光标不变为禁止）
        dragover(e) {
            e.preventDefault()
        },
        sureDelete(item){
            this.id = item.id;
            this.deleteDialogVisible = true;
        },
        cancel(){
            this.deleteDialogVisible = false;
        },
        deleteImg(){
            page.aflpClassFocusDelete(this.id, res => {
                if(res.status === 1) {
                    this.deleteDialogVisible = false;
                    Message.success('删除成功！');
                    this.getAflpClassFocusRecord();
                }else {
                    Message.error(res.message);
                }
            });
        },
        handleSuccess(res, file) {
            console.log(res, file)
            this.upLoadImg = res.data
            this.aflpUnLockAdd();
        },
        beforeUpload(file) {
            const isLt1M = file.size / 1024 / 1024 < 1;

            if ((file.type !== 'image/png') && (file.type !== 'image/jpeg') && (file.type !== 'image/jpg')) {
                this.$message.error('请上传 jpg/jpeg 或 png 格式的图片!');
                return false;
            }
            if (!isLt1M) {
                this.$message.error('上传图片大小不能超过 1MB!');
                return false;
            }
        },
        aflpUnLockAdd(){
            const params = {
                imgUrl: this.upLoadImg,
                schedulingId: this.paikeId
            }
            page.aflpUnLockAdd(params, res => {
                if(res.status === 1) {
                    Message.success('新增成功！');
                    this.getAflpClassFocusRecord();
                }else {
                    Message.error(res.message);
                }
            });
        },
        // 图片放大
        imgView(item){
            console.log(item)
            this.viewingImg = item.imgUrl;
            this.imgViewdialogVisible = true
        }
  },
};
</script>

<style lang="less" scoped>
.checkWork {
    height: 100%;
    width: 100%;
    .checkWork-con{
        padding: 15px;
    }
    .imageList{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        
        .image{
            height: 220px;
            width: 80%;
            margin-left: 10%;
            background: #fff;
            border-radius: 25px;
            position: relative;
        }
        .image:hover{
                -webkit-box-shadow: #ccc 0px 10px 10px;
                -moz-box-shadow: #ccc 0px 10px 10px;
                box-shadow: #ccc 0px 10px 10px;
                transition: all  0.5s;
        }
        .imageItem{
            flex: 0 0 15%;
            box-sizing: border-box;
            margin-right: 2%;
            margin-bottom: 40px;
            .img{
                display: inline-block;
                border-top-left-radius:25px;
                border-top-right-radius:25px;
                display: block;
                cursor: pointer;
                width: 100%;
                height: 180px;
            }
            .delete{
                width: 100%;
                height: 50px;
                text-align: right;
            }
            .deleteIcon{
                cursor: pointer;
                display: inline-block;
                font-size: 24px;
                color: #FB0101;
                line-height: 50px;
                margin-right: 20px;
                z-index: 10000
            }
        }
    }
    .addImg{
        position: relative;
        height: 220px;
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
            display: inline-block;
            color: #BEBCBD;
            font-size: 40px;
            text-align: center;
            margin-top: 55px;
        }
        .words{
            position: absolute;
            left:0;
            right:0;
            top:70px;
            bottom:0;
            margin:auto;
            color: #BEBCBD;
            .title{
                font-size: 20px;
                text-align: center;
                display: inline-block;
            }
        }
        .add{
            width: 80%;
            height: 100%;
            background: #fff;
            border-radius: 25px;
            cursor: pointer;
        }
    }
    .addImg>div{
        width: 100%;
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .imageList>span{
        width: 100%;
    }
    /deep/ .el-loading-mask{
        z-index: 10;
    }
    /deep/ .el-loading-spinner{
        display: none;
    }
    /deep/ .el-loading-mask{
        border-radius: 25px;
    }
    .imageItem:nth-child(6){
        margin-right: 0%;
    }
    .imageItem:nth-child(12){
        margin-right: 0%;
    }
    .imageItem:nth-child(18){
        margin-right: 0%;
    }
    .imageItem:nth-child(24){
        margin-right: 0%;
    }
    .imageItem:nth-child(30){
        margin-right: 0%;
    }
    .imageItem:nth-child(36){
        margin-right: 0%;
    }
}
.sort-move {
    transition: transform 0.3s;
}
.transition-wrapper{
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}
    .checkSucCard{
        position: fixed;
        left: 50%;
        top: 48%;
        width: 760px;
        height: 475px;
        background: url("../../assets/image/bg-login-card.png") center/cover no-repeat;
        transform: translateY(-50%) translateX(-50%);
        .checkSuc{
            position: absolute;
            left: 65px;
            top: 40px;
            width: 630px;
            height: 410px;
            background: #FFFCFD;
            border-radius: 135px;
            .tip{
                position: absolute;
                left: 50%;
                top: -16%;
                width: 400px;
                height: 110px;
                background: url("../../assets/image/tip.png") center/cover no-repeat;
                transform: translateY(-50%) translateX(-50%);
            }
            .delete{
                position: absolute;
                top: 30%;
                width: 100%;
                .deleteImg{
                    text-align: center;
                    font-size: 25px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 400;
                    color: #939393;
                    .deleteImgTip{
                        letter-spacing:8px;
                    }
                }
            }
            .operation{
                position: absolute;
                left: 50%;
                top: 75%;
                width: 500px;
                height: 110px;
                transform: translateY(-50%) translateX(-50%);
                display: flex;
                flex-flow:  row nowrap;
                justify-content: space-between;
                align-items: center;
                letter-spacing:3px;
                .cancel{
                    width: 200px;
                    height: 60px;
                    border: 1px solid #2CBD74;
                    border-radius: 42px;               
                    color: #2CBD74;
                    line-height: 60px;
                    font-size: 24px;
                    cursor: pointer;
                    text-align: center;
                }
                .deleteImg{
                    width: 200px;
                    height: 60px;
                    background: #2CBD74;
                    border-radius: 42px;
                    color: #FFFCFD;;
                    line-height: 60px;
                    font-size: 24px;
                    cursor: pointer;
                    text-align: center;
                }
            }
        }
    }
    /deep/ .el-upload--picture-card{
        width: 80%;
        height: 100%;
        background: #fff;
        border-radius: 25px;
        border: 1px solid #c0ccda;
    }
    .mask {
        position: absolute;
        background: rgba(101, 101, 101, 0.8);
        color: #ffffff;
        opacity: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 25px;
        .maskIconOne{
            font-size: 50px;
            margin-right: 30px;
        }
        .maskIconTwo{
            font-size: 50px;
        }
    }
    .mask h2 {
        text-align: center;
        margin-top: 25%;
    }
    .image:hover .mask {
        opacity: 1;
    }
    .opIcon{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35%;
    }
</style>
<style lang="scss" scoped>
    .students::-webkit-scrollbar {
        display: none;/*隐藏滚动条*/
    } 
    .checkWork-con::-webkit-scrollbar {
        display: none;/*隐藏滚动条*/
    }
    .checkWork-con{
        height: calc(100vh - 110px);
        overflow-y: scroll;
    }   
</style>